// import {  Routes, Route, Navigate } from 'react-router-dom';

// import Home from './pages/Website/Home';
// import Login from './pages/Website/Login';

// import './styles.css';
// import VerifySuccessModal from './components/Modal/VerifySuccessModal';

// import Signup from './pages/Website/Signup';
// import Loading from './pages/Website/Loading';
// import Categories from './pages/Website/Categories';
// import OrderSummary from './pages/Website/OrderSummary';
// import CartOffCanvas from './pages/Website/Cart'
// import PrivacyPolicy from './pages/Website/PrivacyPolicy';
// import Terms from './pages/Website/Terms';


// import ShippingPolicy from './pages/Website/ShippingPolicy';
// import RefundPolicy from './pages/Website/RefundPolicy';
// import Orders from './pages/Profile/Orders';
// import Faq from './pages/Website/Faq';
// import ProfileWishlist from './pages/Profile/ProfileWishlist';
// import ResetPasswordModal from './components/Modal/ResetPasswordModal';
// import PersonalData from './pages/Profile/PersonalData';
// import NewPassword from './pages/Profile/NewPassword';
// import ForgotPassword from './pages/Website/ForgotPassword';
// import OrderConfirmation from './pages/Profile/OrderConfirmation';
// import OrderDetails from './pages/Profile/OrderDetails';
// import Search from './pages/Website/Search';
// import SidebarNavbar from './components/Navbar/SideNavbar';
// import ProductPage from './pages/Website/ProductPage';

// import { PreventLoginAccessRoute, ProtectedRoute } from './App';
// import OrderModification from './pages/Profile/OrderModification';
// import Subscriptions from './pages/Website/foodbundles';
// import SubscriptionSummary from './pages/Website/foodbundlesummary';
// import UserSubscriptions from './pages/Profile/UserSubscriptions';
// import CancelSubscription from './pages/Profile/CancelSubscription';
// import Vouchers from './pages/Website/Vouchers';
// import PurchaseVoucher from './pages/Website/PurchaseVoucher';
// import Refer from './pages/Profile/Refer';



// const RoutesConfig = ({ isMobile }) => (

//      <Routes>
//    <Route path="/" element={<Home/>} />
//    <Route path="/terms" element={<Terms/>} />

//    <Route element={<PreventLoginAccessRoute />}>
//    <Route path="/login" element={<Login/>} />
//    <Route path="/signup" element={<Signup/>} />
//    <Route path="/forgot-password" element={<ForgotPassword/>} />
//         </Route>
 
//    <Route path="/faq" element={<Faq/>} />
//    <Route path="/search" element={<Search/>} />
//    {isMobile && <Route path="/cart" element={<CartOffCanvas/>} />}
//    {isMobile && <Route path="/dashboard" element={<SidebarNavbar/>} /> }
//    <Route path="/products/:productName/:productId" element={<ProductPage />} />
//    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
//    <Route path="/shipping-policy" element={<ShippingPolicy/>} />
//    <Route path="/refund-policy" element={<RefundPolicy/>} />     
//    <Route path="/loading/:token" element={<Loading/>} />
//    <Route path="/verify-email" element={<VerifySuccessModal/>} />
//    <Route path="/reset-password" element={<ResetPasswordModal/>} />
//    <Route path="/category/:categoryName/:categoryId" element={<Categories/>} />
//    <Route path="/foodbundles" element={<Subscriptions/>} />
//    <Route path="/vouchers" element={<Vouchers/>} />
//    <Route path="/vouchers/purchase/:voucherId" element={<PurchaseVoucher/>} />
//    <Route path="/subscription-summary/:boxId" element={<SubscriptionSummary />} />

//    <Route path="/order-confirmation" element={<OrderConfirmation />} />

// {/* Customers */}
//    <Route element={<ProtectedRoute />}>
//           <Route path="/profile/orders" element={<Orders />} />
//           <Route path="/profile/refer" element={<Refer />} />
//           <Route path="/profile/order-details/:orderId" element={<OrderDetails />} />
//           <Route path="/profile/order-modification/:orderId" element={<OrderModification />} />
         
         
//           <Route path="/profile/personal-data" element={<PersonalData />} />
//           <Route path="/order-summary" element={<OrderSummary />} />
         
//           <Route path="/profile/password-update" element={<NewPassword />} />
//           <Route path="/profile/wishlist" element={<ProfileWishlist />} />
//           <Route path="/profile/subscriptions" element={<UserSubscriptions />} />
//           <Route path="/profile/subscriptions/cancel/:subscriptionId" element={<CancelSubscription />} />
//         </Route>
       



   
       
         
     
//    <Route path="*" element={<Navigate to="/" />} />
//     </Routes>
  
// )

// export default RoutesConfig;

import React, { Suspense, lazy} from 'react';
import {Routes, Route, Navigate } from 'react-router-dom';

 import SidebarNavbar from './components/Navbar/SideNavbar';

 import { PreventLoginAccessRoute, ProtectedRoute } from './App';
 
// Lazy loading pages and components
import Home from './pages/Website/Home';
const Login = lazy(() => import('./pages/Website/Login'));
const Signup = lazy(() => import('./pages/Website/Signup'));
const Faq = lazy(() => import('./pages/Website/Faq'));
const Search = lazy(() => import('./pages/Website/Search'));
const ProductPage = lazy(() => import('./pages/Website/ProductPage'));
const PrivacyPolicy = lazy(() => import('./pages/Website/PrivacyPolicy'));
const Terms = lazy(() => import('./pages/Website/Terms'));
const ShippingPolicy = lazy(() => import('./pages/Website/ShippingPolicy'));
const RefundPolicy = lazy(() => import('./pages/Website/RefundPolicy'));
const Categories = lazy(() => import('./pages/Website/Categories'));
const Subscriptions = lazy(() => import('./pages/Website/Subscriptions'));
const SubscriptionSummary = lazy(() => import('./pages/Website/SubscriptionSummary'));
const Vouchers = lazy(() => import('./pages/Website/Vouchers'));
const PurchaseVoucher = lazy(() => import('./pages/Website/PurchaseVoucher'));
const OrderSummary = lazy(() => import('./pages/Website/OrderSummary'));
const Loading = lazy(() => import('./pages/Website/Loading'));
const VerifySuccessModal = lazy(() => import('./components/Modal/VerifySuccessModal'));
const ResetPasswordModal = lazy(() => import('./components/Modal/ResetPasswordModal'));
const CartOffCanvas = lazy(() => import('./pages/Website/Cart'));
const ProfileWishlist = lazy(() => import('./pages/Profile/ProfileWishlist'));
const PersonalData = lazy(() => import('./pages/Profile/PersonalData'));
const NewPassword = lazy(() => import('./pages/Profile/NewPassword'));
const ForgotPassword = lazy(() => import('./pages/Website/ForgotPassword'));
const OrderConfirmation = lazy(() => import('./pages/Profile/OrderConfirmation'));
const OrderDetails = lazy(() => import('./pages/Profile/OrderDetails'));
const Orders = lazy(() => import('./pages/Profile/Orders'));
const OrderModification = lazy(() => import('./pages/Profile/OrderModification'));
const UserSubscriptions = lazy(() => import('./pages/Profile/UserSubscriptions'));
const CancelSubscription = lazy(() => import('./pages/Profile/CancelSubscription'));
const Refer = lazy(() => import('./pages/Profile/Refer'));

const RoutesConfig = ({ isMobile }) => (
  <Suspense fallback={<div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>}>
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/terms" element={<Terms/>} />

    <Route element={<PreventLoginAccessRoute />}>
    <Route path="/login" element={<Login/>} />
    <Route path="/signup" element={<Signup/>} />
    <Route path="/forgot-password" element={<ForgotPassword/>} />
         </Route>
 
    <Route path="/faq" element={<Faq/>} />
    <Route path="/search" element={<Search/>} />
    {isMobile && <Route path="/cart" element={<CartOffCanvas/>} />}
    {isMobile && <Route path="/dashboard" element={<SidebarNavbar/>} /> }
    <Route path="/products/:productName/:productId" element={<ProductPage />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
    <Route path="/shipping-policy" element={<ShippingPolicy/>} />
    <Route path="/refund-policy" element={<RefundPolicy/>} />     
    <Route path="/loading/:token" element={<Loading/>} />
    <Route path="/verify-email" element={<VerifySuccessModal/>} />
    <Route path="/reset-password" element={<ResetPasswordModal/>} />
    <Route path="/category/:categoryName/:categoryId" element={<Categories/>} />
    <Route path="/foodbundles" element={<Subscriptions/>} />
    <Route path="/vouchers" element={<Vouchers/>} />
    

    <Route path="/order-confirmation" element={<OrderConfirmation />} />

 {/* Customers */}
    <Route element={<ProtectedRoute />}>
           <Route path="/profile/orders" element={<Orders />} />
           <Route path="/profile/refer" element={<Refer />} />
           <Route path="/profile/order-details/:orderId" element={<OrderDetails />} />
           <Route path="/profile/order-modification/:orderId" element={<OrderModification />} />
           <Route path="/vouchers/purchase/:voucherId" element={<PurchaseVoucher/>} />
           <Route path="/subscription-summary/:boxId" element={<SubscriptionSummary />} />
         
           <Route path="/profile/personal-data" element={<PersonalData />} />
           <Route path="/order-summary" element={<OrderSummary />} />
         
           <Route path="/profile/password-update" element={<NewPassword />} />
           <Route path="/profile/wishlist" element={<ProfileWishlist />} />
           <Route path="/profile/subscriptions" element={<UserSubscriptions />} />
           <Route path="/profile/subscriptions/cancel/:subscriptionId" element={<CancelSubscription />} />
         </Route>
       

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Suspense>
);

export default RoutesConfig;